<template>
  <!-- 短信模板详情 -->
  <div class="appletMessageDetail-page">
    <div class="return" @click="returnList">
      <i class="iconfont its_ba_fanhui"></i>
    </div>
    <div class="info">
      <div class="add-btn">
        <el-button
          v-if="listFind('添加模板')"
          type="primary"
          size="small"
          @click="openDialogFun('新增模板')"
          >添加</el-button
        >
      </div>
      <div class="table-box">
        <Table
          :table-data="tableData"
          :title-name="titleName"
          operation
          col-class-type
          operation-width="200"
        >
          <template slot-scope="scope">
            <div>
              <template>
                <el-button
                  v-if="listFind('详情编辑')"
                  type="text"
                  size="small"
                  sort="primary"
                  @click="openDialogFun('编辑模板', scope.scopeRow)"
                  >编辑</el-button
                >
                <el-button
                  v-if="listFind('详情删除')"
                  type="text"
                  size="small"
                  sort="primary"
                  @click="onDelete(scope.scopeRow)"
                  >删除</el-button
                >
              </template>
            </div>
          </template>
        </Table>
      </div>
      <div ref="pagination" class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="form.pageSize"
          :current-page="form.currentPage"
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        ></el-pagination>
      </div>
    </div>

    <div class="dialog-box">
      <el-dialog
        v-if="dialogVisible"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="40%"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="业务名称：" prop="miniProgramBusinessCode">
            <el-select
              v-model="miniProgramBusinessCode"
              :disabled="dialogTitle === '编辑模板'"
              value-key="miniProgramBusinessCode"
              clearable
              placeholder="请选择业务代码"
              @change="changeSelect"
            >
              <el-option
                v-for="(item, index) in miniProgramBusinessCodeList"
                :key="index"
                :label="item.miniProgramBusinessName"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模板ID：" prop="miniProgramModelId">
            <el-input
              v-model.trim="ruleForm.miniProgramModelId"
              placeholder="请输入模板ID"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="跳转页面：" prop="miniProgramPagePrefix">
            <el-input
              v-model.trim="ruleForm.miniProgramPagePrefix"
              placeholder="请输入跳转页面"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmFun">确 认</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  queryMiniProgramModelPageAPI,
  queryMiniProgramBusinessCodePageAPI,
  saveMiniProgramModelAPI,
  updateMiniProgramModelAPI,
  deleteMiniProgramModelByIdAPI,
  //
  querySmsModelPage, //列表
  deleteSmsModelById, //删除
  updateSmsModel, //更新
  saveSmsModel, //添加
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogTitle: "",
      miniProgramBusinessCode: {},
      channelNo: "",
      ruleForm: {
        miniProgramBusinessName: "",
        miniProgramBusinessCode: "",
        miniProgramModelId: "",
        miniProgramPagePrefix: "",
      },
      form: {
        currentPage: 1,
        pageSize: 10,
      },
      rules: {
        miniProgramBusinessCode: [
          { required: true, message: "请选择业务代码", trigger: "change" },
        ],
        miniProgramModelId: [
          { required: true, message: "请输入模板ID", trigger: "blur" },
        ],
        miniProgramPagePrefix: [
          { required: true, message: "请输入模板ID", trigger: "blur" },
        ],
      },
      miniProgramBusinessCodeList: [], //业务代码数据
      tableData: [],
      titleName: [
        {
          title: "业务名称",
          props: "miniProgramBusinessName",
        },
        {
          title: "模板ID",
          props: "miniProgramModelId",
        },
        {
          title: "跳转页面",
          props: "miniProgramPagePrefix",
        },
      ],
      total: 0,
    };
  },
  filters: {},
  computed: {},
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.dialogTitle = "";
        this.ruleForm = this.$options.data().ruleForm;
        this.miniProgramBusinessCode = {};
        this.miniProgramBusinessCodeList = [];
      }
    },
  },
  created() {},
  mounted() {
    this.channelNo = this.$route.query.channelNo;
    this.getTableData();
  },
  methods: {
    // 返回列表
    returnList() {
      // this.$router.push("/projectTravel/appletMessageConfiguration");
      this.$router.push({
        name: "appletMessageConfiguration",
        params: { from: "appletMessageDetail" },
      });
    },
    /**
     * @description 请求列表数据
     */
    getTableData() {
      this.form.channelNo = this.channelNo;
      queryMiniProgramModelPageAPI(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data?.list || [];
          this.total = res.data.total;
        }
      });
    },
    // 当前分页改变
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.getTableData();
    },
    // 分页大小改变
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.getTableData();
    },
    /**
     * @description 打开弹窗
     */
    openDialogFun(title, row) {
      this.dialogTitle = title;
      if (title === "新增模板") {
      } else {
        this.miniProgramBusinessCode = {
          miniProgramBusinessCode: row.miniProgramBusinessCode,
          miniProgramBusinessName: row.miniProgramBusinessName,
        };
        this.ruleForm = JSON.parse(JSON.stringify(row));
      }
      this.getQueryBusinessCodeList();
      this.dialogVisible = true;
    },
    /**
     * @description 删除确认框
     */
    onDelete(scope) {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        type: "warning",
        message: h("p", null, [h("span", null, "确认删除？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteMiniProgramModelByIdAPI({
            id: scope.id,
          }).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message.success("删除成功");
              this.getTableData();
            }
          });
        })
        .catch(() => {});
    },
    /**
     * @description 确认回调
     */
    confirmFun() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = { ...this.ruleForm, channelNo: this.channelNo };
          if (this.dialogTitle === "新增模板") {
            saveMiniProgramModelAPI(data).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("新增成功");
                this.dialogVisible = false;
                this.getTableData();
              }
            });
          } else if (this.dialogTitle === "编辑模板") {
            updateMiniProgramModelAPI(data).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("修改成功");
                this.dialogVisible = false;
                this.getTableData();
              }
            });
          }
        }
      });
    },
    /**
     * @description 获取业务代码数据
     */
    getQueryBusinessCodeList() {
      queryMiniProgramBusinessCodePageAPI({
        currentPage: 1,
        pageSize: 999,
      }).then((res) => {
        if (res.code == "SUCCESS") {
          this.miniProgramBusinessCodeList = res.data?.list || [];
        }
      });
    },
    //选择渠道
    changeSelect(val) {
      this.ruleForm.miniProgramBusinessName = val.miniProgramBusinessName;
      this.ruleForm.miniProgramBusinessCode = val.miniProgramBusinessCode;
    },
  },
};
</script>

<style lang="scss" scoped>
.appletMessageDetail-page {
  height: calc(100% - 52px);
  .return {
    width: 32px;
    height: 32px;
    color: #333333;
    font-size: 15px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
  }
  i {
    cursor: pointer;
  }
  .table-box {
    margin-top: 20px;
  }
  .info {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    background-color: #fff;
    margin-top: 10px;
    box-sizing: border-box;
    // overflow: auto;
    // box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
  }
  .dialog-box {
    .demo-ruleForm {
      .el-select {
        width: 100%;
      }
    }
  }
}
</style>
